/* eslint-disable jsx-a11y/anchor-has-content */
// @ts-nocheck
import React, { useEffect, useState } from "react";
import configuration from "../../../../config/configuration";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./index.scss";
import { Link } from "react-router-dom"

const Footer = () => {
  const [info, setinfo] = useState(null);
  const [loaded, setloaded] = useState(false);
  const [affiliate,setAffiliate] =useState(null);
  const [resource,setResource] =useState(null);
  const [legal,setLegal] =useState(null)
  useEffect(() => {
const getAffliate =async () => {
  try{
    let res =await fetch(`${configuration.BACK_END_HOST}/allaffiliates`);
    let resJson = await res.json()
   setAffiliate(resJson);
  }catch(error){
    console.log(error)
  }
  
}
const getResource =async () => {
  try{
    let res =await fetch(`${configuration.BACK_END_HOST}/allrecource`);
    let resJson = await res.json()
   setResource(resJson);
  }catch(error){
    console.log(error)
  }
  
}
// const getLegal =async () => {
//   try{
//     let res =await fetch(`${configuration.BACK_END_HOST}/legal`);
//     let resJson = await res.json()
//    setLegal(resJson);
//    console.log(resJson);
//   }catch(error){
//     console.log(error)
//   }
  
// }
    async function getMoviesFromApi() {
      try {
        let response = await fetch(
          "https://proxy.leerecs.com/?url=https://leerecs.com/indexFile.json"
        );
        let responseJson = await response.json();
        console.log(responseJson);
        setinfo(responseJson);
        setloaded(true)
        
      } catch (error) {
        console.error(error);
      }
    }
    getMoviesFromApi()
    getAffliate();
    getResource();
    // getLegal();
  }, []);
  const currentDate = new Date();
  return (
    <div className="container-fluid" id="footer-section-home">
      <div className="logo-section">
        <div className="logo"></div>
      </div>
      <div className="links-section">
        <div className="first">
          <ul>
            <li>
              <a href="https://play.leerecs.com/login">Login</a>
            </li>
            <li>
              <a href="https://play.leerecs.com/signup">Sign Up</a>
            </li>
            <li>
              <a href="https://play.leerecs.com/request">Request Interview</a>
            </li>
            <li>
              <a href="https://play.leerecs.com/">Start Listening</a>
            </li>
          </ul>
        </div>
        <div className="second">
          <div className="sub-heading">LEGAL</div>
          <ul>
            {info && info.legalLinks.map((item,id)=>{
               return(<li key={id}>
                <a href={item.url} target="_blank"
                  rel="noreferrer">{item.title}</a>
              </li>)
            })}
            
          </ul>
        </div>
        <div className="third">
          <div className="sub-heading">AFFILIATES</div>
          <ul>
            {affiliate && affiliate.data.map((aff,id)=>(<li key={id} title={aff.description}>
              <a href={aff.url} target="_blank"
                rel="noreferrer"><FontAwesomeIcon icon={aff.icon} /> {aff.title}</a>
            </li>))}
          </ul>
        </div>
        <div className="third">
          <div className="sub-heading">RESOURCE</div>
          {resource && <ul>
            {resource.data.map((item, idx) => {
              return (
                <li key={idx} title={item.description}>
                  <a href={item.url} target="_blank"
                    rel="noreferrer"><FontAwesomeIcon icon={item.icon.split(" ")} /> {item.title}</a>
                </li>
              )
            })}
          </ul>}
        </div>
      </div>

      <div className="social-section">
        <div className="notice copyright-txt">
          {info && info.betaMessage}
          
        </div>
        <div className="copyright-txt">&copy; {currentDate.getFullYear()} {info && info.copyright}</div>
        <div className="social-icons">
          <ul>
            {info && info.footerSocialIcons.map((social,id)=>(<a key={id} style={{backgroundImage:`url(${social.icon})`, width:social.width, height:social.height}} href={social.link} target="_blank"
              rel="noreferrer"></a>))}
            
           
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
