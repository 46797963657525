// @ts-nocheck
import React, { useEffect, useState } from "react";
import "./index.scss";

import Landing from "./components/landing/landing";
import Compare from "./components/compare/compare";
import Workings from "./components/workings/workings";

import { BoxLoading } from "react-loadingg";
import {useAppSelector} from "../../redux/hooks"
import { Menu } from "../main/components/welcome/MenuMain";



export default function ProductionService() {
  const [info, setinfo] = useState(null);
  const [loaded, setloaded] = useState(false);
  const [homeData,setHomeData]=useState()
  const { productionRedirect } = useAppSelector(
    (state) => state.productionRedirect
  );
  useEffect(() => {
    async function getMoviesFromApi() {
      try {
        let response = await fetch(
          "https://proxy.leerecs.com/?url=https://leerecs.com/production.json"
        );
        let responseJson = await response.json();
        // console.log("the info", responseJson);
        setinfo(responseJson);
        setloaded(true)
      } catch (error) {
        console.error(error);
      }
    }
    async function getHomeData() {
      try {
        let response = await fetch(
          "https://play.leerecs.com/api/home"
        );
        let responseJson = await response.json();
        setHomeData(responseJson.data);
        //setloaded(true)
      } catch (error) {
        console.error(error);
      }
    }
    if(!loaded){
      getMoviesFromApi();
      getHomeData()
    }

    if (loaded && productionRedirect) {
      window.location.href = "/production#compare";
    }
  }, [loaded, productionRedirect]);
  
  useEffect(() => {
    document.querySelector("#root").style.backgroundColor = "#19245A";
    return () => {
      document.querySelector("#root").style.backgroundColor = "unset";
    }
  }, [])
  

  return (
    <div backgroundColor={"#19245A"} style={{ background:"#19245A"}}>
      {!loaded && (
        <div className="production-preloader">
          <BoxLoading color="#F8107D" />
        </div>
      )}
      <div className="production-page-wrapper">
        <Menu info={homeData} />

        {info && <Landing data={info} />}
        {info && <Compare data={info} />}
        {info && <Workings data={info} />}
      </div>
    </div>
  );
}
