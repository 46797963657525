// @ts-nocheck
import React,{useState,useEffect} from "react";

//images
// import MainImage from "./src/MainImage";
import MainImage from "./assets/NoPath.png";
import headphonesIcon from "./assets/headphones_icon.svg";
import Volume from "./assets/volume.svg";
import File from "./assets/file.svg";

import { Link } from "react-router-dom"

import "./index.scss";

import { useAppDispatch } from "../../../../redux/hooks";
import {redirectElement } from "../../../../redux/data/redirectToProduction/index"
import {useHistory} from "react-router-dom"

const PhysicalMedia = () => {
    const [info, setinfo] = useState(null);
    useEffect(() => {
      async function getMoviesFromApi() {
        try {
          let response = await fetch(
            "https://proxy.leerecs.com/?url=https://leerecs.com/indexFile.json"
          );
          let responseJson = await response.json();
          console.log(responseJson);
          setinfo(responseJson);
          setloaded(true)
          
        } catch (error) {
          console.error(error);
        }
      }
  
      getMoviesFromApi()
  
    },[])
    const dispatch = useAppDispatch()
    const history=useHistory()
    return (
        <div className="container-fluid" id="physical-media">
        <div className="row">
          
          <div className="col-md-6 production-image" style={{ backgroundImage:`url("${info && info.physicalMedia.image}")`}}>
            <div className="title-text">
              Physical Media
            </div>
          </div>
          <div className="col-md-6">
            <div className="main-title">
              {info && info.physicalMedia.title}
            </div>
           
            
            <div className="text h6" style={{fontSize:"1.2em"}}>
               <span className="learn-more">
               {info && info.physicalMedia.description}
               
              </span>
              <p className="mt-3">{info && info.physicalMedia.sub}</p>
              <ol>
              {info && info.physicalMedia.list.map((item) =>(<li>{item}</li>))}
              </ol>

            </div>
            <div className="d-flex btn-list">
              <div >
               <a href={info && info.physicalMedia.buttons[0].url}>
                <div className="btn btn-white">
                  <div className="d-flex">
                    {/* <img src={info && info.physicalMedia.buttons[0].icon} alt="" /> */}
                    <div className="h5">{info && info.physicalMedia.buttons[0].text}</div>
                  </div>
                </div>
                </a>
              </div>
              <a href={info && info.physicalMedia.buttons[1].url} target="_self">
                <div className="btn btn-white">
                  <div className="d-flex gap-1">
                    {/* <img src={info && info.physicalMedia.buttons[1].icon} alt="" /> */}
                    <div className="h5 text-capitalize">{info && info.physicalMedia.buttons[1].text}</div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
}

export default PhysicalMedia;
